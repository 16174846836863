<template>
    <div>
        <v-row align="start" justify="start" dark>
            <v-col cols="12" class="d-flex flex-start align-center">
                <div class="text-h6">Печатные формы вариантов</div>
            </v-col>
        </v-row>

        <v-row align="start" justify="start" dark>
            <v-col cols="12">
                <v-select
                    v-model="form.subject"
                    :items="subjects"
                    label="Выберите предмет"
                ></v-select>

                <v-select
                    v-if="!_.isNil(form.subject)"
                    v-model="form.grade"
                    :items="gradeOptions"
                    label="Выберите класс"
                ></v-select>

                <v-switch 
                    v-if="allowPrint"
                    :disabled="!hasFinal"
                    v-model="form.is_final" 
                    label="Итоговая работа"
                />

                <v-select
                    v-if="allowPrint"
                    v-model="form.option"
                    :items="optionOptions"
                    label="Выберите вариант"
                ></v-select>
            </v-col>
        </v-row>

        <v-divider class="my-4" />

        <div class="text-center">
            <v-btn 
                :disabled="!allowPrint"
                :color="$const.color.primary" 
                class="ml-auto mr-2 white--text" 
                @click.prevent.stop="print"
            >
                <v-icon left dark>
                    mdi-printer
                </v-icon>
                <span>Распечатать</span>
            </v-btn>
        </div>
    </div>
</template>
<script>
import ApiHelper from '@/helpers/ApiHelper';
export default {
    name: 'JobPrint',
    components: { }, 
    data() {
        return {
            form: {
                subject: null,
                grade: null,
                is_final: false,
                option: "1"
            },
            options: [],
            subjects: [],
            
        }
    },
    computed: {
        allowPrint() {
            return !_.isNil(this.form.subject) && !_.isNil(this.form.grade);
        },
        selectedSubject() {
            return _.find(this.subjects, {value: this.form.subject});
        },
        gradeOptions() {
            return _.map(new Array(11), (n,k) => {
                return {
                    text: `${k + 1} кл.`,
                    value: k + 1,
                    disabled: !_.includes(_.get(this, 'selectedSubject.grades', []), k + 1)
                }
            })
        },
        optionOptions() {
            if(!this.allowPrint)
                return [];

            const subject = _.toString(this.form.subject);
            const grade = _.toString(this.form.grade);
            const is_final = this.form.is_final ? 1 : 0;
            const items = this.options[subject][grade][is_final];

            return items ? _.map(items, (o) => {
                return {
                    text: `Вариант ${o.option}`,
                    value: o.option
                }
            }) : [];
        },
        hasFinal() {
            if(this.allowPrint)
            {
                const subject = _.toString(this.form.subject);
                const grade = _.toString(this.form.grade);
                const value = this.options[subject][grade];
                return _.has(value, '1');
            }
            
            return false;
        },
        urlGetParams() {
            return _.mapValues(_.cloneDeep(this.form), (v,k) => {
                if(_.includes(['grade', 'option'], k))
                    return _.isNil(v) ? 0 : _.parseInt(v);
                else if(k === 'is_final')
                {
                    return v === true ? 1 : 0;
                }
                else
                    return v;
            });
        },
        url() {
            return this.allowPrint ? _.join([
                '/job/print/option',
                new URLSearchParams(this.urlGetParams).toString()
            ], '?') : '';
        }
    },
    methods: {
        async fetchOptions() {
            const { success, data } = await ApiHelper.get(`/api/task/options`);
            if(success)
            {
                // Group options by subject+grade+is_final
                let options = _.groupBy(data.items, 'subject');
                for(let subject in options)
                {
                    options[subject] = _.groupBy(options[subject], 'grade');
                    for(let grade in options[subject])
                    {
                        options[subject][grade] = _.groupBy(options[subject][grade], 'is_final');
                    }
                }

                this.options = options;
            }
        },
        async fetchSubjects() {
            const { success, data } = await ApiHelper.get(`${process.env.VUE_APP_CODE_SERVER_HOST}/api/license-type/subjects`, null, {
                api_key: process.env.VUE_APP_CODE_SERVER_API_KEY
            })

            this.subjects = success ? _.filter(data, (o) => o.value !== '*') : [];
        },
        print() {
            window.open(this.url, '_blank');
            // window.location.href = this.url;
        }
    },
    async created() {
        await this.fetchOptions();
        await this.fetchSubjects();
    },
    watch: {
        ['form.subject']() {
            this.form.grade = null;
            this.form.is_final = false;
            this.form.option = "1";
        },
        ['form.grade']() {
            this.form.is_final = false;
            this.form.option = "1";
        },
        ['form.is_final']() {
            this.form.option = "1";
        }
    }
}
</script>